import { PlanClaseAlumnoComponent, ClasePlanClaseComponent, PlanClasesDeAlumnosComponent } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"

const routes: Routes = [
    {
        path: "plan_clases",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClasesDeAlumnosComponent,
                canActivate: [AuthGuard],
                data: {
                    planClaseRoute: "/plan_clases"
                }
            }
        ]
    },
    {
        path: "plan_clases/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClaseAlumnoComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "plan_clases/:id/sesiones/:sesionId",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ClasePlanClaseComponent,
                canActivate: [AuthGuard]
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AlumnosPlanClasesRouting {}
