import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
import { ConversorService } from "../conversor.service"
declare const config: AppConfig

@Component({
    selector: "conversor-box",
    templateUrl: "conversor-box.component.html",
    styleUrls: ["conversor-box.component.scss"]
})
export class ConversorBoxComponent implements OnInit {
    @Input() prueba = "Competencia Matemática"
    psValue: number
    paesValue: number
    @Output() selectedPrueba = new EventEmitter()

    constructor(protected cservice: ConversorService) {}

    ngOnInit() {
        if (this.prueba) {
            this.resetValue()
        }
    }

    resetValue() {
        this.psValue = null
        this.paesValue = null
        this.selectedPrueba.emit(this.prueba)
    }

    overBounds() {
        const bothNull = !this.psValue && !this.paesValue
        if (bothNull) return false

        return this.psValue > 850 || this.paesValue > 1000 || this.psValue < 150 || this.paesValue < 100
    }

    keys() {
        return this.cservice.keys()
    }

    convertValue(psToPaes = true) {
        if (psToPaes) {
            this.paesValue = this.cservice.convertPsToPaes(this.prueba, this.psValue)
        } else {
            this.psValue = this.cservice.convertPaesToPs(this.prueba, this.paesValue)
        }
    }
}
