<footer>
    <div class="bottom-footer" *ngIf="logosExtras?.length > 0">
        <ng-container *ngFor="let logoExtra of logosExtras">
            <a *ngIf="enableLogoFooter && !logoExtra.logoXL" class="footer-logo" href="{{ logoExtra.link }}">
                <img src="{{ logoExtra.src }}" alt="{{ logoExtra.title }}" title="{{ logoExtra.title }}" />
            </a>

            <a *ngIf="enableLogoFooter && logoExtra.logoXL" class="footer-logo-xl" href="{{ logoExtra.link }}">
                <img src="{{ logoExtra.src }}" alt="{{ logoExtra.title }}" title="{{ logoExtra.title }}" />
            </a>
        </ng-container>
    </div>
    <div class="footer-contacto">
        <img
            class="contacto-fondo"
            src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/ubo/footer/footer_fondo.png"
            alt=""
        />
        <img
            class="contacto-texto"
            src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/ubo/footer/footer_textos.png"
            alt=""
        />
    </div>
</footer>
