import { Injectable } from "@angular/core"

@Injectable({ providedIn: "root" })
export class ConversorService {
    conversiones = {
        "Competencia Matemática": {
            preguntas: 60,
            ps: [
                150, 166, 180, 192, 204, 215, 225, 240, 270, 302, 332, 362, 390, 416, 439, 461, 480, 497, 512, 526, 537,
                548, 557, 566, 574, 581, 588, 594, 601, 606, 612, 618, 624, 628, 634, 638, 643, 648, 652, 657, 661, 666,
                670, 675, 680, 683, 689, 694, 699, 704, 710, 718, 724, 732, 740, 750, 762, 776, 801, 824, 850
            ],
            paes: [
                100, 134, 164, 192, 217, 240, 260, 280, 298, 316, 334, 350, 364, 377, 388, 401, 416, 432, 446, 458, 467,
                475, 483, 494, 506, 521, 535, 548, 557, 564, 571, 578, 588, 600, 614, 628, 641, 652, 660, 667, 675, 685,
                698, 713, 728, 740, 752, 762, 774, 786, 802, 819, 836, 852, 868, 885, 904, 926, 949, 974, 1000
            ]
        },
        "Competencia Lectora": {
            preguntas: 60,
            ps: [
                150, 160, 168, 176, 183, 190, 197, 203, 208, 213, 218, 224, 240, 258, 275, 292, 307, 322, 336, 350, 362,
                375, 387, 398, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 509, 518, 528, 538, 548, 556, 566, 576,
                586, 598, 608, 620, 631, 644, 656, 671, 686, 702, 720, 739, 762, 780, 792, 804, 818, 834, 850
            ],
            paes: [
                100, 128, 152, 174, 196, 216, 236, 254, 270, 284, 299, 315, 332, 348, 361, 372, 382, 392, 404, 418, 434,
                448, 461, 470, 478, 486, 494, 505, 518, 534, 548, 560, 570, 578, 585, 593, 603, 617, 632, 647, 660, 671,
                680, 690, 700, 713, 729, 746, 762, 776, 788, 803, 820, 839, 858, 878, 898, 920, 945, 972, 1000
            ]
        },
        /*"Comprensión Lectora": { 
            preguntas: 60,
            ps: [
                150, 160, 168, 176, 183, 190, 197, 203, 208, 213, 218, 224, 240, 258, 275, 292, 307, 322, 336, 350, 362,
                375, 387, 398, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 509, 518, 528, 538, 548, 556, 566, 576,
                586, 598, 608, 620, 631, 644, 656, 671, 686, 702, 720, 739, 762, 780, 792, 804, 818, 834, 850
            ],
            paes: [
                100, 128, 152, 174, 196, 216, 236, 254, 270, 284, 299, 315, 332, 348, 361, 372, 382, 392, 404, 418, 434,
                448, 461, 470, 478, 486, 494, 505, 518, 534, 548, 560, 570, 578, 585, 593, 603, 617, 632, 647, 660, 671,
                680, 690, 700, 713, 729, 746, 762, 776, 788, 803, 820, 839, 858, 878, 898, 920, 945, 972, 1000
            ]
        },*/
        Ciencias: {
            preguntas: 75,
            ps: [
                150, 160, 168, 178, 185, 192, 198, 204, 210, 215, 228, 251, 275, 300, 322, 343, 363, 382, 399, 417, 432,
                449, 460, 473, 484, 495, 506, 515, 524, 532, 540, 548, 554, 561, 568, 574, 580, 586, 592, 597, 601, 607,
                612, 616, 621, 626, 630, 635, 639, 644, 648, 652, 656, 660, 665, 669, 674, 678, 683, 687, 692, 698, 703,
                709, 716, 722, 730, 737, 746, 757, 769, 783, 798, 814, 831, 850
            ],
            paes: [
                100, 131, 154, 180, 202, 222, 240, 257, 274, 290, 305, 318, 330, 341, 354, 367, 380, 393, 403, 410, 417,
                426, 434, 446, 458, 471, 481, 488, 494, 498, 504, 510, 518, 529, 541, 553, 564, 572, 577, 582, 587, 592,
                599, 608, 620, 632, 644, 653, 660, 666, 671, 676, 683, 693, 705, 718, 730, 740, 748, 755, 763, 773, 785,
                799, 812, 825, 837, 849, 863, 879, 896, 914, 934, 953, 976, 1000
            ]
        },
        Historia: {
            preguntas: 60,
            ps: [
                150, 163, 176, 186, 197, 206, 216, 224, 248, 276, 300, 332, 356, 378, 400, 416, 435, 451, 466, 480, 492,
                506, 516, 526, 534, 544, 553, 560, 567, 575, 583, 590, 596, 603, 610, 618, 623, 632, 638, 646, 652, 660,
                665, 674, 682, 690, 697, 706, 714, 722, 734, 745, 756, 768, 778, 788, 798, 810, 822, 836, 850
            ],
            paes: [
                100, 130, 160, 186, 209, 232, 253, 272, 290, 306, 321, 338, 356, 372, 385, 396, 406, 417, 430, 445, 461,
                476, 486, 494, 502, 510, 520, 532, 547, 562, 576, 586, 594, 600, 608, 616, 628, 642, 658, 672, 684, 692,
                700, 710, 720, 734, 750, 766, 780, 792, 804, 818, 834, 852, 870, 888, 907, 927, 950, 974, 1000
            ]
        }
    }

    keys() {
        return Object.keys(this.conversiones)
    }

    convertPsToPaes(prueba, valor) {
        return this.convert(prueba, "ps", valor)
    }

    convertPaesToPs(prueba, valor) {
        return this.convert(prueba, "paes", valor)
    }

    convert(prueba, side, valor) {
        const intValor = parseInt(valor)
        if (side !== "ps" && side !== "paes") return null
        if (!this.conversiones[prueba]) return null
        let otherSide = "ps"
        if (side === "ps") otherSide = "paes"

        let min = this.conversiones[prueba][side][0]
        let max = this.conversiones[prueba][side][this.conversiones[prueba].preguntas]
        if (min > intValor) return null
        if (max < intValor) return null
        let minIndex = 0
        for (let [index, val] of this.conversiones[prueba][side].entries()) {
            if (val <= intValor) {
                min = val
                minIndex = index
            }
        }
        const minOther = this.conversiones[prueba][otherSide][minIndex]
        if (min === intValor) return minOther

        max = this.conversiones[prueba][side][minIndex + 1]
        const x100 = max - min
        const x = intValor - min
        const maxOther = this.conversiones[prueba][otherSide][minIndex + 1]
        const p100 = maxOther - minOther
        return parseInt(minOther + (p100 * x) / x100) || null
    }
}
