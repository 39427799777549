import { Component, OnInit } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

/*#################################

Footer, incluye información de la empresa.

#################################*/

@Component({
    templateUrl: "footer.component.html",
    selector: "footer-component",
    styleUrls: ["footer.component.scss"]
})
export class FooterComponent implements OnInit {
    supportMail: string
    contactPhone: string
    config: typeof config
    logosExtras: any[]
    enableLogoFooter: boolean =
        config.app.baseStyle?.footer?.enableLogo !== undefined ? config.app.baseStyle?.footer?.enableLogo : true
    socials = config.plataforma.info?.social

    constructor() {
        this.config = config
        this.supportMail = config.plataforma.info.soporte.mail
        this.contactPhone = config.plataforma.info.contacto.telefono ? config.plataforma.info.contacto.telefono : ""
    }

    ngOnInit() {
        this.logosExtras = this.config.app.baseStyle?.footer?.logosExtras
            ? this.config.app.baseStyle?.footer?.logosExtras
            : []
    }
}
