import { AppConfig, BaseForm, Validations } from "@puntaje/shared/core"

import { Validators } from "@angular/forms"

declare const config: AppConfig

export class UsuariosCompletarRegistroBrasilClonesForm extends BaseForm {
    companyName: string = ""
    // constructor(){
    //  super();
    //  this.companyName = config.plataforma.info.companyName;
    // }

    public static get formParams(): any {
        const telefonoObligatorio = config.registro.enableTelefono && config.registro.telefonoObligatorio

        return {
            usuario_id: { type: "hidden", visible: true },
            // telefono: {
            //     label: "Teléfono celular",
            //     type: "text",
            //     visible: true,
            //     placeholder: "981234567",
            //     validations: [
            //         Validations.validateTelefonoChile,
            //         telefonoObligatorio ? Validators.required : null
            //     ].filter(x => !!x)
            // }
        }
    }
}
