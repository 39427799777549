import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { UtilModule, TranslationsModule } from "@puntaje/shared/core"
import { routing } from "./alumnos_conversor.routing"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { ConversorModule } from "@puntaje/puntaje/new-modules/conversor"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AlumnosLayoutsModule,
        SharedModule,
        routing,
        Angular2FontawesomeModule,
        UtilModule,
        BannersModule,
        TranslationsModule,
        ConversorModule
    ],

    declarations: [],
    exports: []
})
export class AlumnosConversorModule {}
