import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Injector, ChangeDetectorRef } from "@angular/core"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Usuario,
    Usuarios,
    UsuariosCompletarRegistroForm,
    GrupoUsuarios,
    Lugar,
    Lugares,
    Establecimientos,
    UsuariosCompletarRegistroClonesForm
} from "@puntaje/puntaje/api-services"
import { AuthService, GenericModalComponent, SessionService, BaseForm } from "@puntaje/shared/core"

import { BaseRegisterCompletarComponent } from "../base_register_completar.component"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { UsuariosCompletarRegistroPuntajeForm } from "@puntaje/puntaje/api-services"

@Component({
    selector: "usuario-completar-registro-clones",
    templateUrl: "register-completar-clones.component.html",
    styleUrls: ["../base_register_completar.component.scss"]
})
export class RegisterCompletarClonesComponent extends BaseRegisterCompletarComponent {
    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent
    openModal: EventEmitter<any> = new EventEmitter<any>()
    formType: typeof BaseForm = UsuariosCompletarRegistroClonesForm
    lugarColegio: Lugar
    filteredLugarColegio: Lugar[]

    enableCarreras = config.registro ? config.registro.enableCarreras : false
    universidades = config.registro ? config.registro.universidades || [""] : [""]
    enableFechaNacimiento: boolean = config.registro?.enableFechaNacimiento || false
    enableTelefono: boolean = config.registro?.enableTelefono || false
    enableNivel: boolean = config.registro?.enableNivel || false
    enableColegio: boolean = config.registro?.enableColegio || false
    enableGenero: boolean = config.registro?.enableGenero || false
    enableInstagram: boolean = config.registro?.enableInstagram || false

    colegioObligatorio: boolean = config.registro?.colegioObligatorio || false
    carrerasObligatorio: boolean = config.registro?.carrerasObligatorio || false
    telefonoObligatorio: boolean = config.registro?.telefonoObligatorio || false

    @Input() showInputColegioEgresado: boolean = false

    identificadores = {
        chile: {
            idValue: "rut",
            label: "RUT"
        },
        colombia: {
            idValue: "tiOrCc",
            label: "NUIP"
        },
        mexico: {
            idValue: "curp",
            label: "CURP"
        },
        brasil: {
            idValue: "cpf",
            label: "CPF"
        }
    }

    triedToSubmit: boolean = false

    constructor(
        usuariosService: Usuarios,
        authService: AuthService,
        sessionService: SessionService,
        router: Router,
        injector: Injector,
        grupoUsuariosService: GrupoUsuarios,
        cdr: ChangeDetectorRef,
        lugaresService: Lugares,
        establecimientosService: Establecimientos
    ) {
        super(
            usuariosService,
            authService,
            sessionService,
            router,
            injector,
            grupoUsuariosService,
            cdr,
            lugaresService,
            establecimientosService
        )

        this.formType = UsuariosCompletarRegistroClonesForm
        this.params = this.formType.formParams
        this.usuarioPaisParams = this.params["usuario_" + this.pais].class.formParams
    }

    updatePreferencias(preferencias) {
        this.usuario.preferencia_carrera_sedes = preferencias
    }

    save() {
        this.triedToSubmit = true
        UsuariosCompletarRegistroClonesForm.markFormControlsAsTouched(this.form)
        UsuariosCompletarRegistroClonesForm.markFormControlsAsDirty(this.form)

        this.params.colegio.validations = (this.params.colegio.validations ?? []).filter(v => v != Validators.required)
        this.form.controls.colegio.setValidators(this.params.colegio.validations)
        if (
            this.colegioObligatorio &&
            !this.usuario.establecimiento &&
            (+this.usuario.nivel_id != 13 || this.showInputColegioEgresado)
        ) {
            this.params.colegio.validations.push(Validators.required)
            this.form.controls.colegio.setValidators(this.params.colegio.validations)
            this.form.controls.colegio.updateValueAndValidity()

            return
        }

        this.usuario.establecimiento_id = this.usuario.establecimiento?.id

        super.save()
    }
}
