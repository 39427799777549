import { Component, Input, OnChanges, OnInit } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
import { ConversorService } from "../conversor.service"
declare const config: AppConfig

@Component({
    selector: "conversor-table",
    templateUrl: "conversor-table.component.html",
    styleUrls: ["conversor-table.component.scss"]
})
export class ConversorTableComponent implements OnInit, OnChanges {
    @Input() prueba: string
    total = 1
    ps: Array<number> = []
    paes: Array<number> = []

    constructor(protected cservice: ConversorService) {}

    ngOnInit() {
        this.setup()
    }

    ngOnChanges(changes) {
        if (changes["prueba"]) {
            this.setup()
        }
    }

    setup() {
        if (!this.prueba) {
            this.reset()
            return
        }

        this.total = this.cservice.conversiones[this.prueba].preguntas
        this.ps = this.cservice.conversiones[this.prueba].ps
        this.paes = this.cservice.conversiones[this.prueba].paes
    }

    reset() {
        this.total = 1
        this.ps = []
        this.paes = []
    }
}
