import { RestrictedLayoutComponent } from "../layouts/restricted_layout/restricted_layout.component"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import {
    RealizarEnsayoComponent,
    EvaluacionesMultiplesCompartidasComponent,
    GenerarEnsayoMultipleComponent,
    EvaluacionesCorregirComponent,
    EnsayoInstanciasComponent,
    EnsayosMensualesComponent,
    EnsayosRecomendadosComponent,
    EvaluacionesCompartidasComponent,
    GenerarEnsayoComponent,
    ImprimirEnsayoComponent,
    ResultadosEnsayoComponent
} from "@puntaje/puntaje/core"
import { EvaluationLayoutComponent } from "../layouts/evaluation_layout/evaluation_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

import { HomeSaberComponent } from "./home_saber.component"
import { HomeSaber11Component } from "./home_saber11.component"
import { HomePruebasComponent } from "./home_pruebas.component"

import { ProgresoAlumnoComponent, EstadisticasAlumnoComponent } from "@puntaje/puntaje/core"

export const routes: Routes = [
    {
        path: "ensayo_instancias",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluacion_instancias/subir",
        component: RestrictedLayoutComponent,
        children: [
            {
                path: "",
                component: EvaluacionesCorregirComponent,
                data: { typePlatform: "alumnoTwo", perfil: "alumno", titulo: "Subir resultados de Evaluaciones" },
                canActivate: [TokenGuard, AuthGuard, TokenGuard]
            }
        ]
    },
    {
        path: "evaluacion_instancias/subir_ejercicios",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EvaluacionesCorregirComponent,
                data: { typePlatform: "alumnoThree", titulo: "Subir resultados de Evaluaciones" },
                canActivate: [TokenGuard, AuthGuard, TokenGuard]
            }
        ]
    },
    {
        path: "ensayos/generar_ensayo/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayos/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: RealizarEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayos/:id/imprimir",
        component: RestrictedLayoutComponent,
        children: [{ path: "", component: ImprimirEnsayoComponent, canActivate: [TokenGuard, AuthGuard, TokenGuard] }]
    },
    {
        path: "evaluaciones/recomendadas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayosRecomendadosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayo_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluaciones/instancias/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluaciones/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: RealizarEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluaciones/:id/imprimir",
        component: RestrictedLayoutComponent,
        children: [{ path: "", component: ImprimirEnsayoComponent, canActivate: [TokenGuard, AuthGuard, TokenGuard] }]
    },
    {
        path: "evaluaciones/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCompartidasComponent }]
    },
    {
        path: "ensayos_breves",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayosMensualesComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluaciones/:id/corregir",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCorregirComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    //Calugas
    {
        path: "saber/generar_simulacro/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    { path: "saber/estadisticas", component: EstadisticasAlumnoComponent, canActivate: [TokenGuard, AuthGuard] },
    { path: "saber/mi_progreso", component: ProgresoAlumnoComponent, canActivate: [TokenGuard, AuthGuard] },
    {
        path: "saber/resultados",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "saber",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeSaberComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "saber/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCompartidasComponent }]
    },

    {
        path: "saber11",
        canActivate: [TokenGuard, AuthGuard],
        children: [
            { path: "", component: HomeSaber11Component, canActivate: [TokenGuard, AuthGuard] },
            // simulacros multiples
            {
                path: "generar_simulacro",
                component: GenerarEnsayoMultipleComponent,
                canActivate: [TokenGuard, AuthGuard]
            },
            { path: "compartidos", component: EvaluacionesMultiplesCompartidasComponent },
            // simulacros por asignatura
            {
                path: "generar_simulacro/:asignatura_id",
                component: GenerarEnsayoComponent,
                canActivate: [TokenGuard, AuthGuard]
            },
            { path: "estadisticas", component: EstadisticasAlumnoComponent, canActivate: [TokenGuard, AuthGuard] },
            { path: "mi_progreso", component: ProgresoAlumnoComponent, canActivate: [TokenGuard, AuthGuard] },
            { path: "resultados", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] },
            {
                path: "compartidos/asignatura",
                component: EvaluacionesCompartidasComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },

    {
        path: "pruebas/generar_prueba",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoComponent,
                data: { hideButtonDescargar: true },
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    { path: "pruebas/estadisticas", component: EstadisticasAlumnoComponent, canActivate: [TokenGuard, AuthGuard] },
    { path: "pruebas/mi_progreso", component: ProgresoAlumnoComponent, canActivate: [TokenGuard, AuthGuard] },
    {
        path: "pruebas/resultados",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "pruebas/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCompartidasComponent }]
    },
    {
        path: "pruebas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomePruebasComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
