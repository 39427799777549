import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { Facultad, Facultades } from "@puntaje/carreras/api-services"
import { AppConfig } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
declare const config: AppConfig

@Component({
    selector: "buscador-carreras-showcasebox",
    templateUrl: "buscador-carreras-showcasebox.component.html",
    styleUrls: ["buscador-carreras-showcasebox.component.scss"]
})
export class BuscadorCarrerasShowcaseBoxComponent implements OnInit {
    facultades: Facultad[]

    @Input() universidad: string

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(protected facultadesService: Facultades, protected router: Router) {}

    ngOnInit() {
        if (this.universidad) {
            this.loadFacultades()
        }
    }

    loadFacultades() {
        this.loadingLayout.standby()
        const facultadParams = {
            include: "[carrera_sedes]",
            universidad: {
                universidad: this.universidad
            }
        }
        this.facultadesService.where(facultadParams).then(facultades => {
            this.facultades = facultades
            this.loadingLayout.ready()
        })
    }

    goToCarrera(event) {
        this.router.navigate(["/carrera_sedes", event.target.value])
    }
}
