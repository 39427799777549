import { RouterModule, Routes } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { ConversorPageComponent } from "@puntaje/puntaje/new-modules/conversor"

export const routes: Routes = [
    {
        path: "conversor",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ConversorPageComponent , canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
