import { Component, Input } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "conversor-page",
    templateUrl: "conversor-page.component.html",
    styleUrls: ["conversor-page.component.scss"]
})
export class ConversorPageComponent {
    @Input() pagina = "conversor"
    prueba: string

    setPrueba(prueba) {
        this.prueba = prueba
    }
}
