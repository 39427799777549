<cui-showcasebox>
    <cui-showcasebox-title-icon>
        <!-- <ogr-icon name="search"></ogr-icon> -->
        <fa [name]="'search'"></fa>
    </cui-showcasebox-title-icon>
    <cui-showcasebox-title-text>{{ "orientacion.buscador_carreras_showcasebox.title" | t }}</cui-showcasebox-title-text>
    <cui-showcasebox-content>
        <loading-layout #loadingLayout>
            <select *ngFor="let facultad of facultades" class="form-control" (change)="goToCarrera($event)">
                <option value="" disabled selected>{{ facultad.facultad }}</option>
                <option *ngFor="let carreraSede of facultad.carreraSedes" [value]="carreraSede.id">
                    {{ carreraSede.alias }}
                </option>
            </select>
        </loading-layout>
    </cui-showcasebox-content>
</cui-showcasebox>
