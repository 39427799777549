<cui-table *ngIf="total > 1" [withPaginator]="false">
    <tr cui-table-header-row>
        <th class="center">Respuestas Correctas</th>
        <th class="center">Puntaje escala PDT/PSU (Antigua)</th>
        <th class="center">Puntaje en Escala PAES (Nuevo)</th>
    </tr>
    <tr cui-table-data-row *ngFor="let n of [].constructor(total+1); let i = index">
        <td class="center">{{ i }}</td>
        <td class="center">{{ ps[i] }}</td>
        <td class="center">{{ paes[i] }}</td>
    </tr>
    <cui-table-no-data>Seleccione una prueba</cui-table-no-data>
</cui-table>
