<ll-over-title>
    <arte-home-showcasebox-barra [pagina]="pagina"></arte-home-showcasebox-barra>
</ll-over-title>

<ll-titulo>
    Convertidor de Escala
</ll-titulo>

<div class="row">
    <div class="col-md-4">
        <conversor-box (selectedPrueba)="setPrueba($event)"></conversor-box>
    </div>
    <br>
    <div class="col-sm-8">
        <conversor-table [prueba]="prueba"></conversor-table>
    </div>
</div>
