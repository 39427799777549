import { NebuModule } from "@puntaje/nebulosa/api-services"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { UtilModule, FormModule, TranslationsModule, SorterModule, PaginatorModule } from "@puntaje/shared/core"
import { AudiovisualesModule } from "@puntaje/puntaje/new-modules/audiovisuales"
import { CommonModule } from "@angular/common"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { ModalModule } from "ngx-bootstrap/modal"
import { PopoverModule } from "ngx-bootstrap/popover"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { TypeaheadModule } from "ngx-bootstrap/typeahead"
import { RouterModule } from "@angular/router"
import { AgmCoreModule } from "@agm/core"
import { CUIInputsModule, CUITextsModule, CUITablesModule, CUIContainersModule, CUILayoutsModule } from "@puntaje/shared/cui"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown"
import { ConversorBoxComponent } from "./conversor-box/conversor-box.component"
import { ConversorShowcaseBoxComponent } from "./conversor-showcasebox/conversor-showcasebox.component"
import { ConversorTableComponent } from "./conversor-table/conversor-table.component"
import { ConversorPageComponent } from "./conversor-page/conversor-page.component"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"

@NgModule({
    imports: [
        AgmCoreModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        UtilModule,
        CommonsLayoutsModule,
        Angular2FontawesomeModule,
        FormModule,
        ModalModule,
        ProgressbarModule,
        PopoverModule,
        TooltipModule,
        SorterModule,
        PaginatorModule,
        NebuModule,
        TranslationsModule,
        AudiovisualesModule,
        SharedModule,
        BannersModule,
        TypeaheadModule.forRoot(),
        CUITextsModule,
        CUIInputsModule,
        CUITablesModule,
        CUIContainersModule,
        CUILayoutsModule,
        NgMultiSelectDropDownModule.forRoot()
    ],
    declarations: [
      ConversorBoxComponent,
      ConversorTableComponent,
      ConversorShowcaseBoxComponent,
      ConversorPageComponent
    ],
    exports: [
      ConversorBoxComponent,
      ConversorTableComponent,
      ConversorShowcaseBoxComponent,
      ConversorPageComponent
    ],
    providers: []
})
export class ConversorModule {}
