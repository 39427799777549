import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { AuthService, SessionService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Usuario, Usuarios } from "@puntaje/puntaje/api-services"

@Component({
    templateUrl: "home.component.html",
    styleUrls: ["home.component.scss"]
})
export class HomeComponent implements OnInit {
    config: typeof config
    @Input() pagina: string = "home"
    isPro = false
    dias = false
    dias_faltantes = 0
    frase: string = ""
    mailSinConfirmar: boolean = false
    //@ViewChild('loadingLayout') loadingLayout: LoadingLayoutComponent;
    asignaturasBoxInsteadPlanClase = false

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private authService: AuthService,
        protected sessionService: SessionService,
        protected usuariosService: Usuarios,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        this.config = config
    }

    ngOnInit() {
        this.isPro = this.sessionService.isPro()
        this.setMailSinConfirmar()

        this.loggedLayoutService.setOptions({ freeContentStyle: true, noTitleBar: true })
    }

    setMailSinConfirmar() {
        const confirmed_at = this.sessionService.getConfirmedAt()
        if (!confirmed_at) {
            this.mailSinConfirmar = true
        }
    }
}
