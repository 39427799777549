<div>
    <div class="row">
        <div class="col-md-12">
            <select class="form-control" [(ngModel)]="prueba" (change)="resetValue()">
                <option [ngValue]="undefined" [selected]="true">Seleccione Prueba</option>
                <option *ngFor="let k of keys()" [ngValue]="k" [selected]="k === prueba">
                    {{ k }}
                </option>
            </select>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-6">
            Escala PDT/PSU (Antigua)
        </div>
        <div class="col-md-6">
            <input id="ps_value_c" type="number" min="150" max="850" [(ngModel)]="psValue"
                (ngModelChange)="convertValue()" class="form-control" placeholder="150" />
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-6">
            Escala PAES (Nuevo)
        </div>
        <div class="col-md-6">
            <input id="paes_value_c" type="number" min="100" max="1000" [(ngModel)]="paesValue"
                (ngModelChange)="convertValue(false)" class="form-control" placeholder="100" />
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-12">
            <p>Si el puntaje no está explícito en las tablas del Demre, se calculará proporcional</p>
        </div>
    </div>
    <br>
    <div *ngIf="overBounds()" class="row">
        <div class="p-3 mb-2 col-md-12">
            <p class="bg-danger">Valor fuera de rango</p>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-12">
            <cui-button id="clean_c" size="small" class="centered btn-comenzar" (click)="resetValue()">
                Limpiar
            </cui-button>
        </div>
    </div>
</div>
