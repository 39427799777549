<ll-over-title>
    <arte-home-showcasebox-barra [pagina]="pagina"></arte-home-showcasebox-barra>
</ll-over-title>

<div *ngIf="mailSinConfirmar" class="alert alert-warning alert-dismissible" role="alert">
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <strong>¡Advertencia!</strong>
    No ha confirmado su correo electrónico, por favor verificarlo.
</div>
<div class="row">
    <div class="col-md-4 col-sm-6">
        <usuario-showcasebox></usuario-showcasebox>
        <br />
        <a href="https://www.ubo.cl/admision/la-ubo-te-acompana/" target="_blank">
            <img
                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/ubo/banners/estudiar_UBO_2025.png"
                style="width: 100%"
            />
        </a>
    </div>
    <div class="col-md-4 col-sm-6">
        <noticias-alumnos-showcasebox></noticias-alumnos-showcasebox>
        <br />
        <buscador-carreras-showcasebox universidad="Universidad Bernando O'Higgins"></buscador-carreras-showcasebox>
    </div>
    <div class="col-md-4 col-sm-6">
        <arte-home-showcasebox [pagina]="pagina"></arte-home-showcasebox>
        <br />
    </div>
</div>
<arte-zona-modal [pagina]="pagina"></arte-zona-modal>
