<ng-container *ngIf="!saved && form">
    <form novalidate [formGroup]="form">
        <div class="row">
            <div class="col-md-6">
                <h3>Datos Personales</h3>
                <form-input [form]="form" [params]="params['id']" [(value)]="usuario['id']" [key]="'id'"></form-input>
                <div>
                    <div>
                        <label class="label-completado">Nombre</label>
                        <p class="valor-completado">{{ usuario.nombre }}</p>
                    </div>
                    <div>
                        <label class="label-completado">Apellido Paterno</label>
                        <p class="valor-completado">{{ usuario.apellido_paterno }}</p>
                    </div>
                    <div>
                        <label class="label-completado">Apellido Materno</label>
                        <p class="valor-completado">{{ usuario.apellido_materno | easyplaceholder: "-" }}</p>
                    </div>
                    <div>
                        <label class="label-completado">{{ identificadores[pais].label }}</label>
                        <p class="valor-completado">{{ usuario["usuario_" + pais][identificadores[pais].idValue] }}</p>
                    </div>
                </div>

                <h3>Datos de la Cuenta</h3>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['usuario_id']"
                    [(value)]="usuario['usuario_' + pais]['usuario_id']"
                    [key]="'usuario_id'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['email']"
                    [(value)]="usuario['email']"
                    [key]="'email'"
                ></form-input>
                <form-input
                    *ngIf="enableTelefono"
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['telefono']"
                    [(value)]="usuario['usuario_' + pais]['telefono']"
                    [key]="'telefono'"
                    [withLabel]="false"
                >
                    <label for="telefono" [attr.for]="'telefono'">
                        Teléfono celular
                        <fa
                            [name]="'question-circle-o'"
                            [tooltip]="
                                'Te recomendamos agregar tu celular para que puedas activar o recuperar tu cuenta fácilmente a través de SMS.'
                            "
                        ></fa>
                        <span *ngIf="telefonoObligatorio" class="required-mark"></span>
                    </label>
                </form-input>

                <form-input
                    *ngIf="enableFechaNacimiento"
                    [form]="form"
                    [params]="params['fecha_nacimiento']"
                    [(value)]="usuario['fecha_nacimiento']"
                    [key]="'fecha_nacimiento'"
                ></form-input>

                <form-input
                    *ngIf="enableGenero"
                    [form]="form"
                    [params]="params['genero']"
                    [(value)]="usuario['genero']"
                    [key]="'genero'"
                ></form-input>

                <form-input
                    *ngIf="enableInstagram"
                    [form]="form"
                    [params]="params['instagram']"
                    [(value)]="usuario['instagram']"
                    [key]="'instagram'"
                ></form-input>

                <form-input
                    *ngIf="enableNivel"
                    [form]="form"
                    [params]="params['nivel_id']"
                    [(value)]="usuario['nivel_id']"
                    [key]="'nivel_id'"
                ></form-input>

                <div *ngIf="enableColegio && ($any(usuario['nivel_id']) != '13' || showInputColegioEgresado)">
                    <label [attr.for]="'lugarColegio'">Comuna de tu colegio</label>
                    <autocomplete
                        [placeholder]="'Escribe y selecciona entre las opciones'"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="lugarColegio"
                        [options]="filteredLugarColegio"
                        (filterChange)="getLugarColegio($event)"
                        (controlValue)="updateLugarColegio($event)"
                        class="autocomplete"
                    ></autocomplete>

                    <label [attr.for]="'colegio'">
                        {{ params["colegio"].label }}
                        <span *ngIf="colegioObligatorio" class="required-mark"></span>
                    </label>
                    <autocomplete
                        [placeholder]="'Escribe y selecciona entre las opciones'"
                        [formControlName]="'colegio'"
                        [options]="filteredEstablecimientos"
                        (filterChange)="getEstablecimientos($event)"
                        (controlValue)="updateColegio($event)"
                        showTextFun="toStringWithIdPais"
                        class="autocomplete"
                        [disabled]="!lugarColegio"
                    ></autocomplete>
                    <form-input-error
                        [form]="form"
                        [params]="params['colegio']"
                        [value]="usuario['colegio']"
                        [key]="'colegio'"
                    ></form-input-error>
                </div>
            </div>

            <div class="col-md-6">
                <form-input
                    [form]="form"
                    [params]="params['password']"
                    [enablePasswordBar]="true"
                    [(value)]="usuario['password']"
                    [key]="'password'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['password_confirmation']"
                    [(value)]="usuario['password_confirmation']"
                    [key]="'password_confirmation'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['terminos_y_condiciones']"
                    [(value)]="usuario['terminos_y_condiciones']"
                    [key]="'terminos_y_condiciones'"
                ></form-input>

                <div *ngIf="enableCarreras">
                    <h3>
                        Preferencia carreras
                        <span *ngIf="carrerasObligatorio" class="required-mark"></span>
                    </h3>
                    <embed-preferencia-carrera-sedes-by-sede
                        #preferencias
                        [universidadesNombres]="universidades"
                        [form]="form"
                        [amount]="3"
                        (emitPreferencias)="updatePreferencias($event)"
                    ></embed-preferencia-carrera-sedes-by-sede>
                    <error-messages
                        *ngIf="carrerasObligatorio"
                        [isExpanded]="triedToSubmit && usuario.preferencia_carrera_sedes?.length == 0"
                        containerId="preferencias-error"
                    >
                        <ng-container *errorMessage>Las preferencias de carrera son obligatorias.</ng-container>
                    </error-messages>
                </div>

                <button (click)="save()" type="button" class="btn btn-default btn-block submit-btn">Actualizar</button>
                <button (click)="back()" type="button" class="btn btn-default btn-block cancel-btn">
                    Volver a la página de Inicio
                </button>
            </div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="saved && mailChanged">
    Hemos enviado un e-mail a tu correo electrónico con instrucciones para validar tu cuenta. Si no te llega en 5
    minutos más, por favor revisa tu carpeta de spam.
</ng-container>

<ng-container *ngIf="saved && !mailChanged">
    Tus datos han sido actualizados correctamente. En unos segundos te redirigiremos a la página principal.
</ng-container>
